import styles from "../styles/PushSubscriptionItem.module.scss";
import React, {useState} from "react";
import ToggleSwitch from "../../components/admin/sidebar/items/ToggleSwitch";
import {getDeviceStringFromUserAgent} from "./NotificationSettings";
import {capitalize} from "../../utils/StringUtilities";
import FontAwesome from "../../components/utilities/FontAwesome";

export function NotificationItem(props: {
    push_subscription: PushSubscription,
    deletePushSubscription: (sub: PushSubscription) => void,
}) {
    const [on, setOn] = useState(true);
    const icon = props.push_subscription.device_type === "mobile"
        ? <FontAwesome ariaHidden={true}
                       prefix={"fas"}
                       name="fa-mobile-alt"/>
        : <FontAwesome ariaHidden={true}
                       prefix={"fas"}
                       name="fa-laptop"/>;

    function handleDelete() {
        setOn(false);
        setTimeout(() => props.deletePushSubscription(props.push_subscription), 500)
    }

    return <div className={styles.itemRow}>
        <div className={styles.icon}>
            {icon}
        </div>
        <div className={styles.subscriptionType}>
            <p>{props.push_subscription.organization.title}</p>
            <small>{`${getDeviceStringFromUserAgent(props.push_subscription.user_agent)} `}</small>
            <small>{props.push_subscription?.device_type ? capitalize(props.push_subscription.device_type) : "App"} Notifications</small>
        </div>
        <div style={{flexGrow: 1}}>
            <ToggleSwitch onClick={handleDelete} on={on} />
        </div>
    </div>
}
