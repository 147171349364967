import WatsonApi from "../../backends/WatsonApi";
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import NotificationManager from "../../components/notifications/NotificationManager";
import {NotificationItem} from "./NotificationItem";
import styles from "../styles/PushSubscriptionItem.module.scss";
import {IPersonBlockWithPushSubscriptions} from "./UserSettingsPage";
import ToggleSwitch from "../../components/admin/sidebar/items/ToggleSwitch";
import {formatPhoneNumber} from "../../components/modals/PersonModal/TeacherBio";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {observer} from "mobx-react";
import PhoneNumber from "../../components/utilities/PhoneNumber";
import InlineTextEditor from "../../components/utilities/InlineTextEditor";
import FontAwesome from "../../components/utilities/FontAwesome";
import useSWR from "swr";
import {blockObjectFactory} from "../../components/blocks/SchoolBlocks/blockUtils";
import SchoolBlocksLoadingIndicator from "../../components/utilities/SchoolBlocksLoadingIndicator";

const {UAParser} = require("ua-parser-js");

export function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    }).then(function (permissionResult) {
        if (permissionResult !== 'granted') {
            throw new Error("Permission not granted to enable push notifications! Please try again.");
        }
    });
}

export function isPushSupported() {
    return ('serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window);
}

export function getDeviceStringFromUserAgent(userAgent) {
    const parsed = new UAParser(userAgent).getResult();
    let deviceModel = parsed.device.model;
    if (!deviceModel) {
        // ua-parser doesn't handle desktop devices, this is the best guess
        // see https://github.com/faisalman/ua-parser-js/issues/182
        deviceModel = `${parsed.os.name} Desktop`;
    }
    return `${deviceModel || "Unknown Device"}/${parsed.browser.name}`
}

export const NotificationSettings = observer((props) => {
    const {organizationStore, interfaceStore, userStore} = useContext(StoreContext);

    const [pushSupported, setPushSupported] = useState(false);
    const [viewSubmitButton, setViewSubmitButton] = useState(false);
    const {data, isLoading} = useSWR<any, IPersonBlockWithPushSubscriptions>("fetch-user-obj-with-subscriptions", async () => {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_users_read({
                organization_pk: organizationStore.currentOrganization.id,
                id: userStore.id,
                expand: ["push_subscriptions.organization"],
            });
            return blockObjectFactory("person", {blockModel: JSON.parse(response.data)} as IPersonBlock);
        } catch (e) {
            console.error(e);
            return {
                message: "Error fetching User object with push subscriptions.",
            }
        }
    }, {
        revalidateOnMount: true,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });0

    const emailEnabled = !!(data?.blockModel.notifications?.email && data.blockModel.email);
    const phoneEnabled = !!(data?.blockModel.notifications?.phone && data.blockModel.mobile_phone);
    const pushEnabled = !!data?.blockModel.notifications?.push;
    const pushSubscriptions = data?.blockModel.push_subscriptions;

    useAsyncEffect(async () => {
        if (pushSubscriptions?.length === 0 && pushEnabled) {
            // effectively turning off push notifications if there are no subscriptions
            await updateNotificationSetting("push");
        } else if (!pushEnabled && pushSubscriptions?.length) {
            // turn on push notifications there are any active subscriptions
            await updateNotificationSetting("push");
        }
    }, [pushSubscriptions?.length, pushEnabled])

    useEffect(() => {
        if ('serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window) {
            navigator.serviceWorker.ready.then(serviceWorker => {
                if (serviceWorker.active) {
                    setPushSupported(true);
                }
            })
        }
    }, []);

    const updateNotificationSetting = async (field: "push" | "phone" | "email") => {
        try {
            const stateMapping = {
                phone: phoneEnabled,
                push: pushEnabled,
                email: emailEnabled,
            }

            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_users_partial_update({
                organization_pk: organizationStore.currentOrganization.id,
                id: data && data?.blockModel.id,
                data: {
                    notifications: {
                        phone: phoneEnabled,
                        email: emailEnabled,
                        push: pushEnabled,
                        [field]: !stateMapping[field],
                    },
                },
            })
            const userObj = JSON.parse(response.data);
            data && data?.setAttributes({
                blockModel: {
                    ...data && data?.blockModel,
                    notifications: userObj.notifications,
                },
            })
            NotificationManager.success("Settings have been updated!");
        } catch (e) {
            throw new Error("Something went wrong! Please try again.")
        }
    }
    async function handleSubmit() {
        let result = {};
        const client = await WatsonApi();
        try {
            result = await client.apis.organizations.organizations_users_partial_update({
                organization_pk: organizationStore.currentOrganization.id,
                id: data && data?.blockModel.id,
                data: {
                    "mobile_phone": data && data?.blockModel.mobile_phone,
                    "email": data && data?.blockModel.email,
                },
            });
            if (result["status"] === 200) {
                setViewSubmitButton(false);
                NotificationManager.success("Notification Settings updated successfully.");
            }
        } catch (e) {
            console.error(`ERROR: ${e.toString()}`);
            NotificationManager.error("Error updating Notification Settings.");
        }
    }

    async function createPushSubscription() {
        try {
            const registration = await navigator.serviceWorker.ready;
            await askPermission();
            const pushSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: "BFcZZ6FiITumPysCi_t9B1TANBHATH7r0Y4bKVRomTVRo14k5eNU0L9LhgZ3ogL7xPkGYh-RqtFN14RlnU3rDWg",
            });
            const client = await WatsonApi();
            const push_subscription_response = await client.apis.users.users_push_subscription_create({
                user_pk: userStore.id,
                data: {
                    organization_id: organizationStore.organization.id,
                    user_id: userStore.id,
                    subscription_object: pushSubscription,
                    user_agent: interfaceStore.userAgent,
                    device_type: new UAParser(interfaceStore.userAgent).getDevice().type || "desktop", // https://github.com/faisalman/ua-parser-js/issues/182
                },
            })
            const newSubscription = JSON.parse(push_subscription_response.data);
            data && data?.setAttributes({
                blockModel: {
                    ...data && data?.blockModel,
                    push_subscriptions: [...data && data?.blockModel.push_subscriptions.filter(sub => {
                        // in case we upserted the new subscription, don't show a duplicate
                        return sub.id !== newSubscription.id
                    }), {
                        ...newSubscription,
                        organization: organizationStore.organization,
                    }],
                },
            })
        } catch (subscriptionError) {
            throw new Error(subscriptionError.message);
        }
    }

    async function deletePushSubscription(push_subscription) {
        const client = await WatsonApi();
        await client.apis.users.users_push_subscription_delete({
            user_pk: userStore.id,
            id: (push_subscription as PushSubscription).id,
        });
        data && data?.setAttributes({
            blockModel: {
                ...data && data?.blockModel,
                push_subscriptions: data && data?.blockModel.push_subscriptions.filter(sub => {
                    return sub.id !== (push_subscription as PushSubscription).id
                }),
            },
        })
    }

    const subscriptionForCurrentDeviceAndOrganization = pushSubscriptions?.find(sub => {
        return getDeviceStringFromUserAgent(sub.user_agent) === getDeviceStringFromUserAgent(interfaceStore.userAgent) &&
            sub.organization_id === organizationStore.organization.id;
    });

    if (!isLoading) {
        return <div id="my-notification-settings-tab">
            <div className={`sb-settings-block-content-heading ${styles.settingsContainer}`}>
                <h3 style={{marginBottom: 10}}>Notification Settings</h3>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={styles.container}>
                            <br/>
                            <h5>Configure which devices will receive notifications</h5>
                            <hr className={styles.sectionBorder}/>
                            <div className={styles.itemRow}>
                                <div className={styles.icon}>
                                    <FontAwesome ariaHidden={true} prefix={"fas"} name="fa-comment"
                                                 className={styles.itemIcon}/>
                                </div>
                                <div className={styles.flexGrow5}>
                                    <InlineTextEditor
                                        key="mobile_phone"
                                        placeholder={"Enter phone..."}
                                        wrapperClassName={styles.editable}
                                        canEdit={true}
                                        text={data && data?.blockModel.mobile_phone ? formatPhoneNumber(data && data?.blockModel.mobile_phone) : ""}
                                        handleTextChange={(newValue) => {
                                            const currentValue = data && data?.blockModel.mobile_phone && formatPhoneNumber(data && data?.blockModel.mobile_phone);
                                            if (newValue !== currentValue) {
                                                setViewSubmitButton(true);
                                            }
                                            const phoneNumber = Number(newValue?.replace(/\D/g, ""));
                                            data && data?.setAttributes({
                                                blockModel: {
                                                    ...data && data?.blockModel,
                                                    mobile_phone: phoneNumber,
                                                },
                                            })
                                        }}
                                        isSimple={true}
                                        showHoverUI={true}
                                    >
                                        {data && data?.blockModel.mobile_phone ?
                                            <PhoneNumber
                                                number={formatPhoneNumber(data && data?.blockModel.mobile_phone)}/> :
                                            <span>Enter Phone</span>}
                                    </InlineTextEditor>
                                    <small>Text Messages</small>
                                </div>
                                <div className={styles.flexGrow1}>
                                    <ToggleSwitch
                                        onClick={() => updateNotificationSetting("phone")}
                                        on={phoneEnabled}
                                        enabled={!!(data && data?.blockModel.mobile_phone)}
                                        title={data && data?.blockModel.mobile_phone ? "Toggle text messages" : "Add a phone number to enable"}
                                    />
                                </div>
                            </div>
                            {viewSubmitButton &&
                                <button className="btn sb-organization-color-element-bg" onClick={handleSubmit}>
                                    Update Phone Number
                                </button>}
                            <hr className={styles.sectionBorder}/>
                            <div className={styles.itemRow}>
                                <div className={styles.icon}>
                                    <FontAwesome ariaHidden={true} prefix={"fas"} name="fa-at"
                                                 className={styles.itemIcon}/>
                                </div>
                                <div className={styles.subscriptionType}>
                                    <InlineTextEditor
                                        key="email"
                                        placeholder={"Enter an email..."}
                                        wrapperClassName={styles.editable}
                                        canEdit={false}
                                        text={data && data?.blockModel.email ? data?.blockModel.email : ""}
                                        handleTextChange={(value) => {
                                            data && data?.setAttributes({
                                                blockModel: {
                                                    ...data && data?.blockModel,
                                                    email: value,
                                                },
                                            })
                                        }}
                                        isSimple={true}
                                        showHoverUI={true}
                                    >
                                        {data && data?.blockModel.email ?
                                            data && data?.blockModel.email :
                                            <span>Enter email...</span>}
                                    </InlineTextEditor>
                                    <small>Email Notifications</small>
                                </div>
                                <div className={styles.flexGrow1}>
                                    <ToggleSwitch
                                        onClick={() => updateNotificationSetting("email")}
                                        on={emailEnabled}
                                        enabled={!!(data && data?.blockModel.email)}
                                        title={data && data?.blockModel.email ? "Toggle email messages" : "Add an email to enable"}
                                    />
                                </div>
                            </div>
                            <hr className={styles.sectionBorder}/>
                            {pushSubscriptions
                                ?.filter(sub => {
                                    return subscriptionForCurrentDeviceAndOrganization ?
                                        sub.id !== subscriptionForCurrentDeviceAndOrganization.id
                                        : true;
                                })
                                .map(sub => {
                                    return <>
                                        <NotificationItem
                                            key={sub.id}
                                            push_subscription={sub}
                                            deletePushSubscription={deletePushSubscription}
                                        />
                                        <hr className={styles.sectionBorder}/>
                                    </>
                                })}
                            <div className={styles.itemRow}>
                                <div className={styles.icon}>
                                    <FontAwesome ariaHidden={true}
                                                 prefix={"fas"}
                                                 name={interfaceStore.isPWA
                                                     ? "fa-mobile-alt"
                                                     : "fa-laptop"
                                                 }
                                                 className={interfaceStore.isPWA
                                                     ? styles.mobileIcon
                                                     : styles.laptopIcon
                                                 }/>
                                </div>
                                <div className={styles.subscriptionType}>
                                    <p>{organizationStore.organization.title}</p>
                                    <small>{getDeviceStringFromUserAgent(interfaceStore.userAgent)}</small>
                                    <small> Notifications</small>
                                </div>
                                <div className={styles.flexGrow1}>
                                    <ToggleSwitch
                                        enabled={pushSupported}
                                        title={pushSupported ? "Enable app notifications on current device" : "Push notifications are not supported on this device or browser"}
                                        onClick={async () => {
                                            if (subscriptionForCurrentDeviceAndOrganization) {
                                                await deletePushSubscription(subscriptionForCurrentDeviceAndOrganization);
                                            } else {
                                                try {
                                                    await createPushSubscription();
                                                } catch (e) {
                                                    NotificationManager.error(e.message);
                                                }
                                            }
                                        }}
                                        on={subscriptionForCurrentDeviceAndOrganization}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    } else {
        return <div className={styles.notificationSettings}>
                <SchoolBlocksLoadingIndicator fill={"$dark-gray"} size={70}/>
            </div>;
    }
}
);

export default NotificationSettings;
